import { styled } from "@mui/material/styles";

export const ColorValue = styled('div')(({ color, bgColor }) => ({
    width: "fit-content",
    fontSize: 14,
    fontWeight: 600,
    borderRadius: 6,
    columnGap: 4,
    display: 'flex',
    alignItems: 'center',
    padding: "4px 8px",
    boxSizing: 'border-box',
    color: color,
    backgroundColor: bgColor,
    "&:before": {
        marginRight:5,
        content: '""',
        height: 10,
        width: 10,
        borderRadius: "50%",
        boxShadow: `0px 0px 4px 0px ${color}`,
        backgroundColor: color
    }
}));
