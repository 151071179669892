import {
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_SUCCESS_MSG,
  FETCH_ERROR,
  ERROR_BLANK,
  SHOW_LOADER,
  IS_USER_ONBOARD,
  FETCH_SUCCESS_MSG_NO_LOADER,
  FETCH_SUCCESS_NO_LOADER,
  HIDE_LOADER,
  APP_MENUS,
  TAKE_NPS,
  PERCENTAGE,
} from "../ActionTypes";
import {
  getToken
} from "../Actions";
import { combine } from "../../utils";
const INIT_STATE = {
  error: false,
  loading: false,
  success: false,
  time: new Date().getTime(),
  message: "",
  is_onboard: -1,
  TAKE_NPS: false,
  countries: [],
  authUser: getToken(),
  menus: [],
  percentage: null
};

const defaultReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return {
        ...state,
        error: false,
        success: false,
        message: "",
        loading: true,
      };
    }
    case FETCH_SUCCESS: {
      return {
        ...state,
        error: false,
        success: false,
        loading: false,
      };
    }
    case FETCH_SUCCESS_NO_LOADER: {
      return {
        ...state,
        error: false,
        success: false,
        loading: true,
      };
    }
    case FETCH_SUCCESS_MSG: {
      return {
        ...state,
        error: false,
        success: true,
        message: (action.payload || '').replace(/\r\n/g, "<br/>"),
        time: new Date().getTime(),
        loading: false,
      };
    }
    case FETCH_SUCCESS_MSG_NO_LOADER: {
      return {
        ...state,
        error: false,
        success: true,
        message: (action.payload || '').replace(/\r\n/g, "<br/>"),
        time: new Date().getTime(),
        // loading: true,
      };
    }
    case FETCH_ERROR: {
      downloadError(action.payload);
      return {
        ...state,
        error: true,
        success: false,
        message: (action.payload || '').replace(/\r\n/g, "<br/>"),
        time: new Date().getTime(),
        loading: false,
      };
    }
    case ERROR_BLANK: {
      return {
        ...state,
        error: false,
        success: false,
        message: "",
        //loading: false,
      };
    }
    case SHOW_LOADER: {
      return {
        ...state,
        // error: false,
        // success: false,
        loading: true,
      };
    }
    case HIDE_LOADER: {
      return {
        ...state,
        percentage:null,
        // error: false,
        // success: false,
        loading: false,
      };
    }
    case PERCENTAGE: {
      return {
        ...state,
        percentage: action.payload.percentage
      };
    }
    case IS_USER_ONBOARD: {
      const { onboard_status, countries } = action.payload;
      return {
        ...state,
        is_onboard: onboard_status,
        countries: combine(countries, "{n}.COUNTRY_ID", "{n}")
      }
    }
    case TAKE_NPS: {
      const TAKE_NPS = action.payload;
      return {
        ...state,
        TAKE_NPS
      }
    }
    case APP_MENUS: {
      return {
        ...state,
        menus: action.payload
      };
    }
    default: {
      return state;
    }
  }
};

const downloadError = (errMessage = '') => {
  if (errMessage.indexOf("Row") !== -1) {
    exportErrData(errMessage);
  }
};

const exportErrData = (errStr = '') => {
  var linkElement = document.createElement("a");
  try {
    var uri = "data:application/csv;base64,";
    var base64 = function (s) {
      return window.btoa(unescape(encodeURIComponent(s)));
    };
    var csv_data = errStr.replace(/<br>/g, "\r\n");
    linkElement.setAttribute("href", uri + base64(csv_data));
    linkElement.setAttribute("download", "upload_err_log.csv");
    var clickEvent = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: false,
    });
    linkElement.dispatchEvent(clickEvent);
  } catch (ex) {

  }
};

export default defaultReducers;
