import { Button, Tooltip } from '@mui/material';
import { styled, makeStyles } from "@mui/styles";
import React from 'react';
import { useSelector } from 'react-redux';
const CustomButton = styled(Button)(({ theme, themecolor }) => ({
  "&.MuiButton-root	": {
    fontSize: 14,
    fontWeight: 600,
    height: "38px",
    borderRadius: "6px",
    boxShadow: `0px 0px 4px 0px ${theme.palette.primary.main}`,

  },
  '&.MuiButton-contained': {
    color: '#FFFFFF',
    backgroundColor: themecolor,
    boxShadow: `0px 0px 3px 0px ${theme.palette.primary.main}`,
    "&:disabled": {
      backgroundColor: `rgb(53 47 47 / 14%) !important`,
      boxShadow: "none",
      color: "gray !important",
      boxShadow: "none"
    },
    '&:hover': {
      backgroundColor: `rgba(${themecolor, 0.8}) !important`,
      color: "white",

    }
  },
  '&.MuiButton-outlined': {
    height: "38px",
    borderRadius: "6px",
    boxShadow: `0px 0px 4px 0px ${theme.palette.primary.main}`,
    border: `1px solid ${themecolor}`,
    color: themecolor,
    // "&:disabled": {
    //   backgroundColor: "white",
    //   color: `rgba(${themecolor, 0.5}) !important`,
    //   border: `1px solid rgba(${themecolor, 0.5})`
    // },
    '&:hover': {
      backgroundColor: `rgba(${themecolor, 0.1}) !important`,
      color: themecolor,
      boxShadow: "none"
    }
  }
}));

const useStyles = makeStyles(theme => ({
  button: {
    [theme.breakpoints.down("md")]: {
      "& .MuiButton-startIcon": {
        margin: 0
      }
    }
  },
  buttonText1: {
    // whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // [theme.breakpoints.down("md")]: {
    //   display: "none"
    // }
  },
  buttonText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  }
}));

const SubmitButton = (props) => {
  const { children, isResponsiove = true, disable = false, title, ...rest } = props;
  const { user = {} } = useSelector((state) => state.auth);
  const classes = useStyles();
  return (
    <Tooltip title={title ? title : null} placement='top' arrow>
      <CustomButton
        disabled={disable}
        themecolor={
          user?.PRODUCT_THEME_COLOR === "#FFFFFF"
            ? "#335aff"
            : user?.PRODUCT_THEME_COLOR
        }
        className={classes.button}
        {...rest}
      >
        <div className={rest.startIcon && isResponsiove ? classes.buttonText : classes.buttonText1}>{children}</div>
      </CustomButton >
    </Tooltip>
  )
}

export default SubmitButton;