import { Box, Stack, Typography } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import React from 'react'
import SubmitButton from '../Buttons/CustomButton';
import { Else, If, Then, When } from 'react-if';
import info from "../../assets/images/info.png";
import warning from "../../assets/images/warning.png";
import checked from "../../assets/images/Checked.png";
import rejected from "../../assets/images/Rejected.png";

function CustomAlert(props) {
    const {
        title = "",
        subtitle = "",
        AlertIcon = "",
        labelCancel = "Cancel",
        labelProceed = "Proceed",
        onCancel = () => { },
        onProceed = () => { },
        isSureShow = true,
        type = "info"
    } = props;

    const getimage = {
        "info": info,
        'error': rejected,
        'warning': warning,
        'success': checked
    }
    return (
        <Stack direction="column" alignItems="center" justifyContent="center" sx={{ color: '#4D70FF' }}>
            <If condition={AlertIcon}>
                <Then>
                    <Box
                        sx={{
                            height: 50,
                            width: 50,
                            borderRadius: '50%',
                            bgcolor: 'background.primary',
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        {AlertIcon}
                    </Box>
                </Then>
                <Else>
                    <img src={getimage[type]} height={70} />
                </Else>
            </If>

            <Typography component='div'
                sx={{
                    fontSize: 18,
                    fontWeight: 700,
                    // mt: 1,
                    color: 'primary.main'
                }}
            >
                {title}
            </Typography>
            <When condition={isSureShow}>
                <Typography component='div' sx={{ fontSize: 15, mt: 1, fontWeight: 600 }}>
                    Are You Sure?
                </Typography>
            </When>
            <Typography component='div' sx={{ textAlign: "center", fontSize: "1rem", mt: 1, fontSize: 15, fontWeight: 600, color: '#656369' }}>
                {subtitle}
            </Typography>
            <Stack direction="row" spacing={2} justifyContent="flex-end" width="100%" sx={{ mt: 2 }}>
                <SubmitButton variant="outlined" onClick={onCancel}>
                    {labelCancel}
                </SubmitButton>
                <SubmitButton variant="contained" onClick={onProceed}>
                    {labelProceed}
                </SubmitButton>
            </Stack>
        </Stack>
    )
}

export default CustomAlert;