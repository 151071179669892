import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Chip from "@mui/material/Chip";
import { Delete as DeleteIcon, CloudUpload as CloudUploadIcon } from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import { post } from "../../AppRedux/Actions";
import { showToast } from "../../AppRedux/constant";
const trimFname = (f_name = '') => {
    f_name = f_name.split("/").pop()
    if (f_name.length > 33) {
        return f_name.slice(0, 15) + '...' + f_name.slice(-15);
    }
    return f_name;
}


const FileBrowser = ({ options = {}, value = '', inputHandler, ...props }) => {

    const dispatch = useDispatch();
    const [files, setFiles] = useState({ f_url: value });

    const { getRootProps, getInputProps } = useDropzone({
        validator: options.customValidatior ?? null,
        accept: options.accept || ["application/vnd.ms-excel"],
        minSize: options.min || 10000,
        maxSize: options.max || 6000000,
        disabled: options.readonly || options.disabled || false,
        onDrop: (acceptedFiles, fileRejections) => {
            if (fileRejections.length > 0) {
                showToast(fileRejections[0].errors[0].message, 'error');
            }
            else {
                handleFileChange(acceptedFiles[0]);
            }

        },
    });
    useEffect(() => {
        setFiles({ f_url: value })
    }, [value])
    const handleFileChange = (file) => {
        if (file) {
            file.f_url = URL.createObjectURL(file);
        } else {
            file = { f_url: '' };
            // if (Object.hasOwnProperty.call(options, 'manualHandler') && typeof options.manualHandler === 'function') {
            //     options.manualHandler(options.name, '');
            // }
        }
        if (Object.hasOwnProperty.call(options, 'manualHandler') && typeof options.manualHandler === 'function') {
            options.manualHandler(options.name, file.f_url.length > 0 ? file : "");
        } else {
            if (file.f_url.length > 0) {
                const data = new FormData();
                data.append('field_name', options.name);
                data.append('file_data', file);
                if (Object.hasOwnProperty.call(options, 'custom_path')) {
                    data.append('filepath', options.custom_path);
                }
                post(dispatch, '', `upload_s3`, data, { "Content-Type": "multipart/form-data" }, resp => {
                    inputHandler({ target: { name: options.name, value: resp.file_name } });
                });
            } else {
                inputHandler({ target: { name: options.name, value: "" } });
            }
        }
        setFiles(file);
    }

    return (<>
        <div {...getRootProps()}>
            <input {...getInputProps()} />
            {files.f_url.length <= 0 && <Chip icon={<CloudUploadIcon />} label={<><span>Drag & drop here or </span><span style={{ color: 'blue' }}>Choose file</span></>} variant="outlined" style={{ cursor: 'pointer', border: 'none', width: "100%", height: "27.4px" }} />}
        </div>
        <aside>
            {files.f_url.length > 0 && <Chip label={trimFname(files.name || files.f_url)} variant="outlined" style={{ border: 'none', width: "100%", height: "27.4px" }} onDelete={()=>handleFileChange()} deleteIcon={<DeleteIcon />} />}
        </aside>
    </>)
}

export default FileBrowser;