import React, { useState } from 'react'
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Autocomplete from '@mui/material/Autocomplete';
import { Case, Default, Switch, When } from 'react-if';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';

import { date_format } from '../../utils';
import './styles.scss';
import dayjs from 'dayjs';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const InputLabeler = (label) => {
    return <InputLabel htmlFor={label}>
        {label}
    </InputLabel>
}

const handleDateChange = (date, name) => {
    return { target: { name: name, value: date_format(new Date(date), 'Y-m-d') } };
}

const handletimeChange = (date, name) => {
    return { target: { name: name, value: dayjs(date).format('HH:mm') } };
}

function timeConversion(timeFormate) {

    const [hours, minutes] = timeFormate?.split(':');

    const dateObject = new Date();
    dateObject.setHours(parseInt(hours, 10));
    dateObject.setMinutes(parseInt(minutes, 10));

    return dateObject;

}

// const Placeholder = ({ children }) => {
//     return <div style={{ color: "gray" }}>{children}</div>;
// };

const ValidateFields = (props) => {
    const { name, errors, options = [], size } = props;
    const [disablePassword, setDisabledPassword] = useState(false);

    let materail_element = ''
    switch (props.type) {
        case "select":
            materail_element = (
                <TextField
                    sx={{
                        width: "100%",
                        '&ul': {
                            maxHeight: "200px"
                        }
                    }}
                    size={size || "small"}
                    error={errors?.[name]}
                    {...props}
                    select
                    autocomplete="off"
                    variant='outlined'
                //MenuProps={MenuProps}
                >
                    {
                        options?.map((item) => {
                            return (
                                <MenuItem value={item.hasOwnProperty('id') ? item?.id : item}>
                                    {item.hasOwnProperty('name') ? item?.name : item}
                                </MenuItem>
                            )
                        })
                    }
                </TextField>
            )
            break;
        case "autoComplete":
            materail_element = (
                <Autocomplete
                    size={size || "small"}
                    options={options}
                    {...props}
                    onInputChange={props.onInputChange}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                    )}
                />)
            break;

        case "date":
            materail_element = (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        disabled={props.disabled}
                        clearable
                        fullWidth
                        allowSameDateSelection
                        inputFormat="DD-MM-YYYY"
                        inputVariant="outlined"
                        openTo="date"
                        size="small"
                        value={props.value}
                        inputProps={{ disabled: true }}
                        sx={props.sx}
                        onChange={(date) => { props.onChange(handleDateChange(date, props.name)) }}
                        renderInput={params => <TextField {...props}  {...params} size="small" />}
                    />
                </LocalizationProvider>)
            break;
        case "time":
            materail_element = (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopTimePicker
                        ampm={false}
                        openTo="hours"
                        views={['hours', 'minutes']}
                        value={props?.value ? timeConversion(props?.value || "00:00") : null}
                        onChange={(date) => { props.onChange(handletimeChange(date, props.name)) }}
                        renderInput={(params) => (<TextField size='small' error={false} {...params} fullWidth />)
                        }
                    />
                </LocalizationProvider>
            )
            break;
        case "multiselect":
            materail_element = (
                <FormControl variant="outlined" size="small" fullWidth>
                    {InputLabeler(name)}
                    <Select
                        id={name}
                        multiple
                        value={props.values || []}
                        onChange={props.inputHandler}
                        required={props.required || false}
                        disabled={props.readonly || props.disabled || false}
                        name={name}
                        input={<OutlinedInput label={`${name}${props.required ? ' * ' : ''}`} />}
                        renderValue={(selected) => {
                            let SelectedName = [];
                            props.options?.forEach(opt => {
                                let id = props.key || 'id'
                                let name = props.value || 'name'
                                if (typeof opt != "object") {
                                    opt = { [id]: opt, [name]: opt };
                                }
                                if (selected.indexOf(opt.id) !== -1) {
                                    SelectedName.push(opt.name);
                                }
                            });
                            return SelectedName.join(', ');
                        }}
                        MenuProps={MenuProps}
                    >
                        {React.Children.toArray(props.options?.map((opt) => {
                            let id = options.key || 'id'
                            let name = options.value || 'name'
                            if (typeof opt != "object") {
                                opt = { [id]: opt, [name]: opt };
                            }
                            return (<MenuItem value={opt.id}>
                                <Checkbox color="default" checked={(props.values || []).indexOf(opt.id) > -1} />
                                <ListItemText primary={opt.name} />
                            </MenuItem>)
                        }))}
                    </Select>
                </FormControl>
            )
            break;
        case 'password':
            materail_element = (
                <FormControl fullWidth variant="outlined" size="small">
                    {InputLabeler(props.label)}
                    <OutlinedInput
                        id="outlined-adornment-password"
                        label={`${props.label} ${props.required ? '*' : ''} `}
                        name={name}
                        error={errors?.[name]?.message}
                        value={props.value === undefined || props.value === null ? '' : props.value}
                        type={disablePassword ? 'text' : 'password'}
                        onChange={props.onChange}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => { setDisabledPassword((prev) => !prev) }}
                                    onMouseDown={(event) => { event.preventDefault(); }}
                                    edge="end"
                                >
                                    {disablePassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            )
            break;
        default:
            materail_element = (
                <TextField
                    size="small"
                    error={errors?.[name]}
                    sx={{ width: '100%' }}
                    {...props}
                    autocomplete="off"
                />)
    }
    return (
        <>
            {materail_element}
            <When condition={errors?.[name]}>
                <div style={{ marginTop: 2, color: 'red' }}>{errors?.[name]?.message}</div>
            </When>
        </>
    )
}

export default ValidateFields;