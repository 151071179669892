import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import PaymentsIcon from '@mui/icons-material/Payments';
import RemoveIcon from '@mui/icons-material/Remove';
import { getUser } from "../../AppRedux/Actions";

// const GET_COUNTRY = getUser('active_country_id');
export const INDIAN_COUNTRY_ID = 101;

export const CTC_TAB = 'ctc';
export const GROSS_TAB = 'gross';

export const EARNING = 'earning';
export const DEDUCTION = 'deduction';
export const COMPLIANCES = 'compliances';
export const ALLOWANCE = 'allowance';
export const EMPLOYER_DEDUCTION = 'employer_deduction'

const INDIA_STRUCTURE_LIST = [
    {
        label: "Compliances",
        HeadID: COMPLIANCES,
        HeadIcon: <TextSnippetIcon />,
        stylesTitle: { color: "#4D8BFF", iconBg: "#E6ECFF" }
    },
    {
        label: "Allowances",
        HeadID: ALLOWANCE,
        HeadIcon: <PaymentsIcon />,
        stylesTitle: { color: "#F7B7A3", iconBg: "#FFF1E6" }
    },
];

const INTERNATINOAL_STRUCTURE_LIST = [
    {
        label: "Employer Deductions",
        HeadID: EMPLOYER_DEDUCTION,
        HeadIcon: <RemoveIcon />,
        stylesTitle: { color: "#45E570", iconBg: "#E9FDF8" }
    },
]


export const STRUCTURE_LIST = (GET_COUNTRY) => {
    return ([
        {
            label: "Earnings",
            HeadID: EARNING,
            HeadIcon: <AddCircleOutlineIcon />,
            stylesTitle: { color: "#45E570", iconBg: "#E9FDF8" }
        },
        {
            label: "Deductions",
            HeadID: DEDUCTION,
            HeadIcon: <TextSnippetIcon />,
            stylesTitle: { color: "#FF6666", iconBg: "#FFF5F5" }
        },
        ...(GET_COUNTRY == INDIAN_COUNTRY_ID ? INDIA_STRUCTURE_LIST : []),
        ...(GET_COUNTRY != INDIAN_COUNTRY_ID ? INTERNATINOAL_STRUCTURE_LIST : []),
    ])
};

export const INIT_GET_CALCULATION = getUser('active_country_id') == INDIAN_COUNTRY_ID ? CTC_TAB : GROSS_TAB;

export const tabList = [
    {
        label: "CTC CALCULATION",
        value: CTC_TAB,
        // icon: <AccountBalanceOutlinedIcon />,

    },
    {
        label: "GROSS CALCULATION",
        value: GROSS_TAB,
        // icon: <PaymentsOutlinedIcon />
    },
];



export const INDIAN_HEAD_OPTIONS = [
    { id: 1, name: "Earning" },
    { id: 2, name: "Deduction" },
    { id: 3, name: "Compliances" },
    { id: 5, name: "Allowance" },
];

export const INTERNATINOAL_HEAD_OPTIONS = [
    { id: 1, name: "Earning" },
    { id: 2, name: "Deduction" },
    { id: 3, name: "Employer Deduction" },
];

export const SYSTEM_HEAD_MSG = 'System heads cannot be disabled';