import * as React from 'react';
import Drawer from '@mui/material/Drawer';

export default function CustomRightDrawer(props) {
    const { open = false, handleCloseRightDrawer = () => { }, ...rest } = props;
    return (
        <div>
            <React.Fragment>
                <Drawer
                    {...rest}
                    anchor={'right'}
                    open={open}
                    onClose={handleCloseRightDrawer}
                    className="right-drawer-container"
                    PaperProps={{
                        style: {
                            boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                        }
                    }}
                    slotProps={{
                        backdrop: {
                            style: {
                                backdropFilter: "blur(3px)",
                                backgroundColor: "#000000cc"
                            }
                        }
                    }}
                    {...rest}
                >
                    {props.children}
                </Drawer>
            </React.Fragment>
        </div>
    );
}