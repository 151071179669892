import React from "react";
import { styled } from "@mui/material/styles";
import { Table, TableBody, TableCell as W, TableContainer, TableHead as H, TableRow } from '@mui/material';
import './styles.scss';

const TableHead = styled(H)(({ headstyle }) => ({
    '& tr': {
        '& th': headstyle
    }
}));

const TableCell = styled(W)(({ border = "none" }) => ({
    zIndex: 2,
    borderBottom: border,
}));


const List = ({ columns, rows, loading = false, headstyle = {}, border = "none", ...rest }) => {
    if (!columns || !rows || typeof columns !== "object" || typeof rows !== "object") {
        return (
            <ol>
                <li>
                    columns : required and should be type of object.
                </li>
                <li>
                    rows : required and should be type of Array of object.
                </li>
            </ol>
        );
    }
    return (
        <TableContainer {...rest}>
            <Table stickyHeader aria-label="table" size="small">
                <TableHead headstyle={headstyle}>
                    <TableRow>
                        {columns.map((column) => React.Children.toArray(
                            <TableCell
                                border={border}
                                align={column.align}
                                style={{ minWidth: column.minWidth, width: column.width, fontWeight: 600 }}
                            >
                                {column.format ? column.format('label', column, true) : column.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, i) => React.Children.toArray(
                        <TableRow hover role="checkbox" tabIndex={-1}>
                            {columns.map((column) => React.Children.toArray(
                                <TableCell border={border} align={column.align} sx={{ fontWeight: 400, fontSize: 14, color: column?.color || '#00000' }}>
                                    {column.id === '#' ? (i + 1) : (column.format ? column.format(column.id, row) : row[column.id])}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default List;