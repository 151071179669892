import { Box, Divider, Stack, Button, TextField, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import './styles.scss';
import { usePayrollHook } from '../../hooks/usePayrollHook';
import { get, post } from "../../AppRedux/Actions";
import { useDispatch } from 'react-redux';
import { OTP_REQUEST, VOICE_OTP_REQUEST, VALIDATE_OTP } from '../../constants';
import { Else, If, Then, When } from 'react-if';
import { valueWithCurrencySymbol } from '../../utils';
import FormBuilder from '../../components/FormBuilder/FormBuilder';

const ItemLabel = styled('div')({
    fontSize: 16,
    fontWeight: 400,
    color: '#A5A3A9',
    '& ~div': {
        fontSize: 14,
        color: '#30323E'
    }
});


const PayoutSummary = () => {
    const {
        disburshmentSummary,
        setDisburshmentSummary,
        filterResult,
        payoutIds,
        setActiveStep,
        activeStep,
        setOpenModal
    } = usePayrollHook();
    const dispatch = useDispatch();
    const [otpBtnisClicked, setOtpBtnisClicked] = useState(false);
    const [counterDisplay, setCounterDisplay] = useState("Resend OTP");
    const [isTimerOn, setIsTimerOn] = useState(true);
    const [errorMsg, setErrorMsg] = useState(null);
    const otpinput = useRef(null);
    let tick = null;
    const init_counter = () => {
        let tic_count = 60;
        setIsTimerOn(true);
        if (tick) {
            clearInterval(tick);
        }
        setCounterDisplay(`${tic_count--} Seconds`);
        tick = setInterval(() => {
            setCounterDisplay(`${tic_count--} Seconds`);
        }, 1000);
        setTimeout(() => {
            clearInterval(tick);
            setIsTimerOn(false);
            setCounterDisplay("Resend OTP");
        }, 60000);
    };

    useEffect(() => {
        if (otpBtnisClicked) init_counter();
    }, [otpBtnisClicked])

    const getOtpRequest = () => {
        post(dispatch, '', OTP_REQUEST, disburshmentSummary, {}, (data) => {
            setOtpBtnisClicked(true);
        });
    }

    const resendOtp = () => {
        if (isTimerOn) return 1;
        post(dispatch, '', VOICE_OTP_REQUEST, disburshmentSummary, {}, (data) => {
            init_counter();
        });
    }

    const submitOtp = () => {
        let otpValue = otpinput.current.value;
        setErrorMsg(null);
        if (!otpValue) {
            otpinput.current.style.color = 'red';
        }
        else {
            otpValue = otpValue.trim();

            if (isNaN(otpValue)) {
                otpinput.current.style.color = 'red';
                setErrorMsg("Otp is not a valid please enter in Number Format");
            }
            else {
                otpinput.current.style.color = 'black';
                post(dispatch, '', VALIDATE_OTP, { ...disburshmentSummary, filters: filterResult, employeList: Object.values(payoutIds), otp: otpValue }, {}, (data) => {
                    otpinput.current.value = "";
                    setActiveStep(activeStep + 1);
                    setOpenModal(false);
                });
            }

        }
    }
    return (
        <Box>
            <Stack>
                <Stack direction="row" justifyContent="space-between" className="summary-margin">
                    <ItemLabel>Payment Month :</ItemLabel>
                    <div>{disburshmentSummary?.payment_month}</div>
                </Stack>
                <Divider />
                <Stack direction="row" justifyContent="space-between" className="summary-margin">
                    <ItemLabel>Employee Count :</ItemLabel>
                    <div>{disburshmentSummary?.no_of_employee}</div>
                </Stack>
                <Divider />
                <Stack direction="row" justifyContent="space-between" className="summary-margin">
                    <ItemLabel>Amount to be Disbursed :</ItemLabel>
                    <div>{valueWithCurrencySymbol(disburshmentSummary?.total_amount)}</div>
                </Stack>
                <Divider />
                <Stack direction="row" justifyContent="space-between" className="summary-margin">
                    <ItemLabel>Available Balance :</ItemLabel>
                    <div>{valueWithCurrencySymbol(disburshmentSummary?.opening_bal)}</div>
                </Stack>
                <Divider />
                <Stack direction="row" justifyContent="space-between" className="summary-margin">
                    <ItemLabel>Remaining Balance :</ItemLabel>
                    <div>{valueWithCurrencySymbol(disburshmentSummary?.balance)}</div>
                </Stack>
                <Divider />
                <Stack direction="row" justifyContent="space-between" alignItems="center" className="summary-margin">
                    <ItemLabel>Payment Mode :</ItemLabel>
                    <div>
                        <FormControl>
                            <RadioGroup
                                row
                                name="txn_mode"
                                value={disburshmentSummary.txn_mode}
                                onChange={(e) => setDisburshmentSummary({ ...disburshmentSummary, [e.target.name]: Number(e.target.value) })}
                            >
                                <FormControlLabel value="1" control={<Radio />} label="IMPS/RTGS" />
                                <FormControlLabel value="2" control={<Radio />} label="NEFT/RTGS" />
                            </RadioGroup>
                    </FormControl>
                    </div>
                </Stack>
                <When condition={errorMsg}>
                    <Box className="otp-notification-size error-otp"><InfoOutlinedIcon /> {errorMsg}</Box>
                </When>
                <When condition={otpBtnisClicked}>
                    <Divider />
                    <When condition={!errorMsg}>
                        <Box className="otp-notification-size info-otp"><InfoOutlinedIcon /> OTP has been sent on registered mobile no +91{disburshmentSummary?.mobile_no}</Box>
                    </When>
                    <Stack direction="row" justifyContent="space-between" className="summary-margin">
                        <ItemLabel>Enter OTP</ItemLabel>
                        <div><TextField
                            size="small"
                            name="otp"
                            placeholder="Please Enter OTP"
                            sx={{ width: '100%' }}
                            inputProps={{
                                ref: otpinput
                            }}
                        /></div>
                    </Stack>
                </When>
            </Stack>

            <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end" width="100%" sx={{ mt: 2 }}>
                <When condition={otpBtnisClicked}>
                    <Button
                        sx={{ color: '#172B4D', backgroundColor: "#EFEFEF", fontWeight: 'bold' }}
                        className="border-btn"
                        disabled={isTimerOn}
                        onClick={resendOtp}
                    >
                        {counterDisplay}
                    </Button>
                </When>
                <If condition={otpBtnisClicked}>
                    <Then>
                        <Button sx={{ color: 'white', background: '#172B4D' }} variant="contained" onClick={submitOtp}>
                            Submit OTP
                        </Button>
                    </Then>
                    <Else>
                        <Button sx={{ color: 'white', background: '#172B4D' }} variant="contained" onClick={getOtpRequest}>
                            Get OTP
                        </Button>
                    </Else>
                </If>

            </Stack>
        </Box>
    )
}

export default PayoutSummary