import React from 'react';
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from '@mui/styles/makeStyles';
import { Box, Typography } from '@mui/material';
import { When } from 'react-if';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    loader: {
        position: "fixed",
        height: "100vh",
        width: "100%",
        top: "0px",
        left: "0px",
        zIndex: "99999",
        textAlign: "center",
        display: 'flex',
        backgroundColor: "rgba(255,255,255,.75)",
        color: theme.palette.primary.main,
    },
    innerLoader: {
        margin: "auto",
    },
    loaderui: {
        color: theme.palette.primary.main,

    }
}));

const FullLoader = () => {
    const classes = useStyles();
    const { percentage } = useSelector((state) => state.common);
    return (
        <div className={classes.loader}>
            <div className={classes.innerLoader}>
                <CircularProgress className={classes.loaderui} size={60}  thickness={1.5}/>
                <When condition={percentage}>
                    <Box
                        sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography variant="caption" component="div" color="text.secondary"
                            sx={{
                                fontWeight: 'bold'
                            }}>
                            {`${Number(percentage)?.toFixed(1)}%`}
                        </Typography>
                    </Box>
                </When>
            </div>
        </div>
    )
}
export default FullLoader;