/* eslint-disable */

import { configureStore } from '@reduxjs/toolkit';
import Common from "./Reducers/Common";
import Auth from "./Reducers/Auth";
export const store = configureStore({
    reducer: {
        common: Common,
        auth: Auth,
        Common
    }
});