import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import {
    Grid,
    InputBase,
    InputLabel,
    TextField,
    FormControl,
    MenuItem,
    Button,
    Paper,
    FormControlLabel,
    Switch,
    FormGroup,
    Checkbox,
    Stack,
    Box,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { usePayrollHook } from '../../hooks/usePayrollHook';
import { post, get } from '../../AppRedux/Actions';
import { useDispatch } from 'react-redux';
import { When } from 'react-if';
import { ADD_UPDATE_BONUS_RULES, ORG_BONUS_HEADS_LIST, ORG_BONUS_RULES } from '../../constants';
import ValidateFields from '../../components/FormBuilder/ValidateFields';
import SwitchIcon from '../../components/Switch/SwitchIcon';
import SubmitButton from '../../components/Buttons/CustomButton';
import { isEmpty } from '../../utils';
import { Item } from '../../components/CustomStyle';
import SquareSwicthIcon from '../../components/Switch/SquareSwicthIcon';
import { getUser } from '../../AppRedux/Actions';
import { INDIAN_COUNTRY_CODE } from '../../utils';
const CustomInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3)
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        border: '1px solid #ced4da',
        fontSize: 16,
        width: "100%",
        padding: '5px 6px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}));

const CustomSelect = styled(TextField)(({ theme }) => ({
    width: "100%",
    'label + &': {
        marginTop: theme.spacing(3)
    },
    '& .MuiInputBase-select': {
        borderRadius: 4,
        position: 'relative',
        border: '1px solid #ced4da',
        fontSize: 16,
        width: "100%",
        padding: '5px 6px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}));

const CustomInputLabel = styled(InputLabel)({
    fontSize: 16,
    fontWeight: 600,
    color: '#30323E'
});

const CardSection = styled(Paper)({
    padding: "0.5rem 1rem",
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: "column",
    rowGap: 3,
    marginTop: 5,
    marginBottom: 5,
    justifyContent: 'space-between',
    boxShadow: '0px 0px 2px #bbb1b1',
    '& div': {
        display: 'flex',
        rowGap: 3,
        // justifyContent: 'space-between',
    }
});

const CreateRules = () => {
    const dispatch = useDispatch();
    const ACTIVE_COUNTRY = getUser('active_country_id');

    const { setOpenModal, setRules, editRules } = usePayrollHook();
    const rulesDetails = Object.keys(editRules).length ? editRules : { CONFIG_NAME: "", STATUS: "1" }
    const BonusTenure = [
        { id: 0, name: "Monthly" },
        { id: 1, name: "On Year completion" },
        { id: 2, name: "Half Yearly" },
        { id: 3, name: "Quaterly" },
        { id: 4, name: "OneTime" },
        { id: 5, name: "After Year completion" }
    ]
    const PaymentTypes = [
        { id: 0, name: "Fixed" },
        { id: 1, name: "Variable" },
    ];

    const CalculationTypes = [
        { id: 1, name: "Flat" },
        { id: 2, name: "Based on Salary" },
    ];

    const AttendanceTypes = [
        { id: 1, name: "Yes" },
        { id: 0, name: "No" },
    ]
    const QuartlyTypes = [
        { id: 1, name: "1" },
        { id: 2, name: "2" },
        { id: 3, name: "3" },
        { id: 4, name: "4" },
    ]

    const MonthlyTypes = [
        { id: 1, name: "1" },
        { id: 2, name: "2" },
        { id: 3, name: "3" },
        { id: 4, name: "4" },
        { id: 5, name: "5" },
        { id: 6, name: "6" },
        { id: 7, name: "7" },
        { id: 8, name: "8" },
        { id: 9, name: "9" },
        { id: 10, name: "10" },
        { id: 11, name: "11" },
        { id: 12, name: "12" },
    ]
    const DisbursementOn = [
        { id: 0, name: "Date of Joining" },
        { id: 1, name: "Fixed Month" },
    ]
    const DisbursementCriteria = Array(12).fill().map((_, i) => ({ id: i + 1, name: i + 1 }))

    function FetchBonusHead() {
        get(dispatch, '', ORG_BONUS_HEADS_LIST, {}, (data) => {
            const activeData = data.map((item) => {
                const extraData = {
                    BONUS_HEAD_ID: item.HEAD_ID,
                    DISBURSEMENT_MONTH: null,
                    DISBURSEMENT_ON: null,
                    FREQUENCY_TENURE: 0,
                    HEAD_NAME: item.HEAD_NAME,
                    HEAD_STATUS: item.STATUS,
                    MIN_DISBURSEMENT_CRITERIA: 0,
                    CALCULATION_TYPE: 1,
                    PAYMENT_LIMITED_TO: item?.PAYMENT_LIMITED_TO || 0,
                    STATUS: 1
                }

                return { ...item, ...extraData }
            });

            setValue("HEADS", [...activeData || []])
        });
    }

    useEffect(() => {
        if (Object.keys(editRules).length) {
            const { HEADS } = editRules;
            setValue("HEADS", [...HEADS || []])
        }
        else {
            FetchBonusHead()
        };
    }, [])

    const Month = [
        { id: 1, name: 'January' },
        { id: 2, name: 'February' },
        { id: 3, name: 'March' },
        { id: 4, name: 'April' },
        { id: 5, name: 'May' },
        { id: 6, name: 'June' },
        { id: 7, name: 'July' },
        { id: 8, name: 'August' },
        { id: 9, name: 'September' },
        { id: 10, name: 'October' },
        { id: 11, name: 'November' },
        { id: 12, name: 'December' }
    ];

    function FetchBonusRules() {
        get(dispatch, '', ORG_BONUS_RULES, {}, (data) => {
            setRules([...data || []]);
        });
    }

    function handleFormDetails(e) {
        const { name, value } = e.target;
        setValue(name, value);

    }

    function handleHeadChecked(index, e) {
        const { HEADS } = values;
        const { checked } = e.target;
        if (!checked) {
            const updateValue =
            {
                DISBURSEMENT_MONTH: null,
                DISBURSEMENT_ON: null,
                FREQUENCY_TENURE: 0,
                MIN_DISBURSEMENT_CRITERIA: null,
                PAYMENT_TYPE: 0,
                CALCULATION_TYPE: 1,
                PAYMENT_LIMITED_TO: 0,
                SALARY_PERCENTAGE: null
            }
            HEADS[index] = { ...HEADS[index], ...updateValue };
        }
        HEADS[index].STATUS = +checked;
        setValue("HEADS", HEADS)
    }

    function handleValueHeadChecked(index, e) {
        const { name, checked } = e.target;
        handleHead(index, { target: { name, value: +checked } })
    }

    function handleHead(index, e) {
        const { HEADS } = values;
        const { name, value } = e.target;
        if (name === "FREQUENCY_TENURE") {
            let updateValue =
            {
                DISBURSEMENT_MONTH: null,
                DISBURSEMENT_ON: null,
                MIN_DISBURSEMENT_CRITERIA: 0,
            }

            if (value) {
                updateValue.DISBURSEMENT_ON = 0;
            }
            HEADS[index] = { ...HEADS[index], ...updateValue };
        }

        if (name === "CALCULATION_TYPE") {

            let updateValue = {
                SALARY_PERCENTAGE: null
            };
            HEADS[index] = { ...HEADS[index], ...updateValue };
        }

        if (name === "DISBURSEMENT_ON") {
            const updateValue =
            {
                DISBURSEMENT_MONTH: null,
                MIN_DISBURSEMENT_CRITERIA: "",
            }
            HEADS[index] = { ...HEADS[index], ...updateValue };
        }

        HEADS[index][name] = value;
        setValue("HEADS", HEADS);
    }

    function handleRulesSubmit() {
        let finalValeus = getValues();
        finalValeus.HEADS = values?.HEADS?.filter((item) => item.STATUS === 1);
        post(dispatch, '', ADD_UPDATE_BONUS_RULES, finalValeus, {}, (data) => {
            FetchBonusRules();
            setOpenModal(false);
        });
    }

    const configurationValidation = Yup.object({
        CONFIG_NAME: Yup.string().required("Required"),
        HEADS: Yup.array().of(Yup.object().shape({
            DISBURSEMENT_MONTH: Yup.string()
                .nullable()
                .when('DISBURSEMENT_ON', {
                    is: 1,
                    then: () => Yup.string()
                        .nullable()
                        .required('Required')
                }),
            SALARY_PERCENTAGE: Yup.string()
                .nullable()
                .when('CALCULATION_TYPE', {
                    is: (id) => id == 2,
                    then: () => Yup.string()
                        .nullable()
                        .test('is-number', 'allow numbers only', value => {
                            return !isNaN(value);
                        })
                        .test('min-range', 'min value is 1', value => {
                            return Number(value) >= 1;
                        })
                        .test('max-range', 'max value is 100', value => {
                            return Number(value) <= 100;
                        })
                        .required('required'),
                }),
            MIN_DISBURSEMENT_CRITERIA: Yup.string()
                .nullable()
                .when('DISBURSEMENT_ON', {
                    is: 1,
                    then: () => Yup.string()
                        .nullable()
                        .required('Required')
                }),
            FREQUENCY_TENURE: Yup.string().required("Required"),
            PAYMENT_LIMITED_TO: Yup.string().required("Required")

        }))
    });

    //validations
    const { register, getValues, watch, handleSubmit, clearErrors, formState: { errors }, setValue } = useForm({
        defaultValues: rulesDetails,
        resolver: yupResolver(configurationValidation)
    });
    const values = watch();

    return (
        <React.Fragment>
            <Box padding={"0.5rem"}>
                <Grid container direction="row" xs={12}>
                    <Grid xs={6}>
                        <FormControl variant="standard" sx={{ width: "100%", paddingRight: '10px' }}>
                            <CustomInputLabel shrink htmlFor="name-input">
                                Head Name
                            </CustomInputLabel>
                            <ValidateFields
                                placeholder="Enter Head Name"
                                errors={errors}
                                name="CONFIG_NAME"
                                defaultValue={values.CONFIG_NAME}
                                onChange={handleFormDetails}
                                inputRef={register("CONFIG_NAME")}
                                sx={{
                                    'label + &': {
                                        marginTop: 3
                                    }
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid xs={6}>
                        <FormControl variant="standard" sx={{ width: "100%", paddingLeft: '10px' }}>
                            <CustomInputLabel shrink htmlFor="name-input" sx={{ marginLeft: "10px" }}>
                                Status
                            </CustomInputLabel>
                            <CustomSelect
                                size="small"
                                select
                                name="STATUS"
                                defaultValue={values.STATUS}
                                onChange={handleFormDetails}
                            >
                                <MenuItem value="1">Active</MenuItem>
                                <MenuItem value="0">InActive</MenuItem>
                            </CustomSelect>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
            <Grid container direction="row" xs={12}>
                <When condition={values.STATUS == "1"}>
                    <Grid xs={12} spacing={2} sx={{ p: 1, maxHeight: 450, overflow: "scroll" }}>
                        {values?.HEADS?.map((head, index) => {
                            return (<CardSection className="page-shadow">
                                <Stack direction="row" alignItems="center" justifyContent={"space-between"}>
                                    <Item style={{ fontSize: 14 }}>{head.HEAD_NAME}</Item>
                                    <FormControlLabel sx={{ marginRight: 0 }} control={<SquareSwicthIcon checked={head.STATUS} onChange={handleHeadChecked.bind(this, index)} />} />
                                </Stack>
                                <Grid container xs={12} sx={{ marginBottom: 2 }} spacing={1} justifyContent="flex-start">
                                    <When condition={head.STATUS}>
                                        <Grid item xs={4}>
                                            <FormControl variant="standard" sx={{ width: "100%" }}>
                                                <CustomInputLabel shrink htmlFor="name-input">
                                                    Frequency Tenure
                                                </CustomInputLabel>
                                                <ValidateFields
                                                    type="select"
                                                    errors={errors['HEADS']?.[index]}
                                                    name="FREQUENCY_TENURE"
                                                    defaultValue={head.FREQUENCY_TENURE}
                                                    onChange={handleHead.bind(this, index)}
                                                    options={BonusTenure}
                                                    inputRef={register("FREQUENCY_TENURE")}
                                                    sx={{
                                                        'label + &': {
                                                            marginTop: 3
                                                        }
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl variant="standard" sx={{ width: "100%" }}>
                                                <CustomInputLabel shrink htmlFor="name-input">
                                                    Payment Type
                                                </CustomInputLabel>
                                                <ValidateFields
                                                    type="select"
                                                    errors={errors}
                                                    name="PAYMENT_TYPE"
                                                    value={head.PAYMENT_TYPE}
                                                    onChange={handleHead.bind(this, index)}
                                                    options={PaymentTypes}
                                                    inputRef={register("PAYMENT_TYPE")}
                                                    sx={{
                                                        'label + &': {
                                                            marginTop: 3
                                                        }
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl variant="standard" sx={{ width: "100%" }}>
                                                <CustomInputLabel shrink htmlFor="name-input">
                                                    Calculation
                                                </CustomInputLabel>
                                                <ValidateFields
                                                    type="select"
                                                    errors={errors}
                                                    name="CALCULATION_TYPE"
                                                    value={head.CALCULATION_TYPE}
                                                    onChange={handleHead.bind(this, index)}
                                                    options={CalculationTypes}
                                                    inputRef={register("CALCULATION_TYPE")}
                                                    sx={{
                                                        'label + &': {
                                                            marginTop: 3
                                                        }
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <When condition={head.CALCULATION_TYPE == 2 && head.STATUS}>
                                            <Grid item xs={4}>
                                                <FormControl variant="standard" sx={{ width: "100%" }}>
                                                    <CustomInputLabel shrink htmlFor="name-input">
                                                        Salary Percentage
                                                    </CustomInputLabel>
                                                    <ValidateFields
                                                        errors={errors?.['HEADS']?.[index]}
                                                        name="SALARY_PERCENTAGE"
                                                        value={head.SALARY_PERCENTAGE}
                                                        onChange={handleHead.bind(this, index)}
                                                        inputRef={register("SALARY_PERCENTAGE")}
                                                        sx={{
                                                            'label + &': {
                                                                marginTop: 3
                                                            }
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </When>
                                        <Grid item xs={4}>
                                            <FormControl variant="standard" sx={{ width: "100%" }}>
                                                <CustomInputLabel shrink htmlFor="name-input">
                                                    Attendance Dependent
                                                </CustomInputLabel>
                                                <ValidateFields
                                                    type="select"
                                                    errors={errors}
                                                    name="ATTENDANCE_DEPENDENT"
                                                    defaultValue={head.ATTENDANCE_DEPENDENT}
                                                    onChange={handleHead.bind(this, index)}
                                                    options={AttendanceTypes}
                                                    inputRef={register("ATTENDANCE_DEPENDENT")}
                                                    sx={{
                                                        'label + &': {
                                                            marginTop: 3
                                                        }
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </When>
                                    <When condition={(head.FREQUENCY_TENURE == 0 || head?.FREQUENCY_TENURE == 3) && head.STATUS && (ACTIVE_COUNTRY == INDIAN_COUNTRY_CODE)}>
                                        <Grid item xs={4}>
                                            <FormControl variant="standard" sx={{ width: "100%" }}>
                                                <CustomInputLabel shrink htmlFor="name-input">
                                                    Limit Frequency By
                                                </CustomInputLabel>
                                                <ValidateFields
                                                    type="select"
                                                    errors={errors}
                                                    name="PAYMENT_LIMITED_TO"
                                                    defaultValue={head.PAYMENT_LIMITED_TO}
                                                    onChange={handleHead.bind(this, index)}
                                                    options={head?.FREQUENCY_TENURE == 0 ? MonthlyTypes : QuartlyTypes}
                                                    inputRef={register("PAYMENT_LIMITED_TO")}
                                                    sx={{
                                                        'label + &': {
                                                            marginTop: 3
                                                        }
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </When>
                                    <When condition={head.FREQUENCY_TENURE && head.STATUS}>
                                        <Grid item xs={4}>
                                            <FormControl variant="standard" sx={{ width: "100%" }}>
                                                <CustomInputLabel shrink htmlFor="name-input">
                                                    Disbursement On
                                                </CustomInputLabel>
                                                <ValidateFields
                                                    type="select"
                                                    errors={errors}
                                                    name="DISBURSEMENT_ON"
                                                    value={head.DISBURSEMENT_ON}
                                                    onChange={handleHead.bind(this, index)}
                                                    options={DisbursementOn}
                                                    inputRef={register("DISBURSEMENT_ON")}
                                                    sx={{
                                                        'label + &': {
                                                            marginTop: 3
                                                        }
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </When>
                                    <When condition={head.DISBURSEMENT_ON && head.FREQUENCY_TENURE && head.STATUS}>
                                        <Grid item xs={4}>
                                            <FormControl variant="standard" sx={{ width: "100%" }}>
                                                <CustomInputLabel shrink htmlFor="name-input">
                                                    Disbusement Month
                                                </CustomInputLabel>
                                                <ValidateFields
                                                    type="select"
                                                    errors={errors?.['HEADS']?.[index]}
                                                    name="DISBURSEMENT_MONTH"
                                                    value={head.DISBURSEMENT_MONTH}
                                                    onChange={handleHead.bind(this, index)}
                                                    options={Month}
                                                    inputRef={register("DISBURSEMENT_MONTH")}
                                                    sx={{
                                                        'label + &': {
                                                            marginTop: 3
                                                        }
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl variant="standard" sx={{ width: "100%" }}>
                                                <CustomInputLabel shrink htmlFor="name-input">
                                                    Disbursement Criteria
                                                </CustomInputLabel>
                                                <ValidateFields
                                                    type="select"
                                                    errors={errors?.['HEADS']?.[index]}
                                                    name="MIN_DISBURSEMENT_CRITERIA"
                                                    value={head.MIN_DISBURSEMENT_CRITERIA}
                                                    onChange={handleHead.bind(this, index)}
                                                    options={DisbursementCriteria}
                                                    inputRef={register("MIN_DISBURSEMENT_CRITERIA")}
                                                    sx={{
                                                        'label + &': {
                                                            marginTop: 3
                                                        }
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </When>
                                </Grid>
                                <When condition={head.DISBURSEMENT_ON == 0 && head.FREQUENCY_TENURE && head.STATUS}>
                                    <Box mt={0.5}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Checkbox onChange={handleValueHeadChecked.bind(this, index)}
                                                    name='RECOVER_IN_FNF'
                                                    checked={Boolean(head.RECOVER_IN_FNF)} />}
                                                label="Recover if working tenure is less than a year" />
                                        </FormGroup>
                                    </Box>
                                </When>
                            </CardSection>
                            )
                        })}
                    </Grid>
                </When>
                <Grid container xs={12} sx={{ marginTop: 2 }} justifyContent="end" columnGap={2}>
                    <Grid>
                        <SubmitButton variant="outlined" onClick={() => setOpenModal(false)}>Cancel</SubmitButton>
                    </Grid>
                    <Grid>
                        <SubmitButton variant="contained" onClick={handleSubmit(handleRulesSubmit)}>Save</SubmitButton>
                    </Grid>
                </Grid>
            </Grid >
        </React.Fragment>

    )
}

export default CreateRules;