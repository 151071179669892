import React, { useState } from 'react';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import { styled } from "@mui/material/styles";
import "./styles.scss";
import { setSymbolOnEmpty, valueWithCurrencySymbol } from '../../utils';
import { When } from 'react-if';
import SubmitButton from '../../components/Buttons/CustomButton';
import { CustomInput } from '@zimyo/components';
import { post } from '../../AppRedux/Actions';
import { useDispatch } from 'react-redux';
import { ADD_EMPLOYEE_PAYROLL_REMARKS } from '../../InternationalPath';

const InputContainer = styled('div')({
    marginTop: 20
});

const InputSection = styled('div')({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 14,
    marginTop: 12,
    marginBottom: 12
});

const InputValue = styled('div')({
    fontSize: 14
});

const InputName = styled('div')({
    fontSize: 14
});

function EmployeePayroll(props) {
    const { payrollDetail, handleCloseRightDrawer } = props;
    const dispatch = useDispatch();

    const { emp_name,
        emp_code,
        NET_PAYABLE,
        computed_gross,
        earning,
        allowance,
        DEDUCTION,
        deduction,
        compliances,
        miscellaneous_heads,
        computed_obj,
        ot,
        emp_arrear,
        emp_bonus_heads,
        reimbursement = [],
        bonus = [],
        statutory_bonus,
        loan_amount,
        adv_amount,
        trip_amount,
        EMPLOYEE_ID,
        remarks,
        OrgConfig
    } = payrollDetail;

    const [remarkText, setRemarkText] = useState(remarks || '')

    function formateNumber(value) {
        value = setSymbolOnEmpty(value, 0);
        value = parseInt(value).toFixed(2);
        return valueWithCurrencySymbol(value)
    }

    const grosspayDetails = [...(earning || []), ...(allowance || []), ...(reimbursement || []), ...(bonus || []), /*...emp_bonus_heads*/, ...(miscellaneous_heads?.earning?.map((item) => {
        return { HEAD_NAME: item.name, head_amount: item.amount }
    }) || [])];

    const Alldeuctions = [...(deduction || []), ...(miscellaneous_heads?.deduction?.map((item) => {
        return { HEAD_NAME: item.name, head_amount: item.amount }
    }) || [])];

    const compliancesList = [];
    // compliances.map((item) => {
    //     return { HEAD_NAME: item.HEAD_NAME, head_amount: item.head_amount }
    // });


    const remarkSubmitHandler = (event) => {
        event.preventDefault();
        post(dispatch, '', ADD_EMPLOYEE_PAYROLL_REMARKS, { "emp_id": EMPLOYEE_ID, "payroll_month_year": OrgConfig?.payroll_month_year, "remark": remarkText }, {}, () => {
        })
    }

    return (
        <Box sx={{ width: 400 }} role="presentation" className="edit-employee">
            <Box className="sticky-head">
                <Box sx={{ float: 'right', m: 1, cursor: 'pointer' }}><CloseIcon onClick={handleCloseRightDrawer} /></Box>
            </Box>
            <Box sx={{ p: 2 }}>
                <Box>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant='body2' component="div" sx={{ color: '#4D70FF' }}>{setSymbolOnEmpty(emp_name, "-")}</Typography>
                        <Typography component="div">Net Payable</Typography>
                    </Stack>
                </Box>
                <Box>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography component="div">Emp  Code :{setSymbolOnEmpty(emp_code, "-")}</Typography>
                        <Typography component="div">{formateNumber(NET_PAYABLE)}</Typography>
                    </Stack>
                </Box>
                <Box>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ backgroundColor: '#E6ECFF26', mt: 3, p: 0.5 }}>
                        <Typography variant='body2' component="div" sx={{ color: '#80ACFF' }}>Gross Salary</Typography>
                        <Typography variant='body2' component="div" sx={{ color: '#00CD37' }}>{formateNumber(computed_gross)}</Typography>
                    </Stack>
                    <InputContainer>
                        {
                            grosspayDetails?.map((item) => {
                                return (
                                    <InputSection>
                                        <InputName>{item?.HEAD_NAME}</InputName>
                                        <InputValue>{formateNumber(item?.head_amount)}</InputValue>
                                    </InputSection>
                                )
                            })
                        }

                        {
                            emp_arrear?.earn_head?.map((item) => {
                                return (
                                    <InputSection>
                                        <InputName>{item?.HEAD_NAME}</InputName>
                                        <InputValue>{formateNumber(item?.head_amount)}</InputValue>
                                    </InputSection>
                                )
                            })

                        }
                        <When condition={statutory_bonus > 0}>
                            <InputSection>
                                <InputName>Statutory Bonus</InputName>
                                <InputValue>{formateNumber(statutory_bonus ?? 0)}</InputValue>
                            </InputSection>
                        </When>
                        <When condition={ot.is_ot_enabled}>
                            <InputSection>
                                <InputName>OT</InputName>
                                <InputValue>{formateNumber(ot.ot_amount)}</InputValue>
                            </InputSection>

                            <InputSection>
                                <InputName>Production Incentive</InputName>
                                <InputValue>{formateNumber(ot?.extra_ot_amount)}</InputValue>
                            </InputSection>
                        </When>
                        <When condition={loan_amount > 0}>
                            <InputSection>
                                <InputName>Loans</InputName>
                                <InputValue>{formateNumber(loan_amount ?? 0)}</InputValue>
                            </InputSection>
                        </When>
                        <When condition={adv_amount > 0}>
                            <InputSection>
                                <InputName>Advance</InputName>
                                <InputValue>{formateNumber(adv_amount ?? 0)}</InputValue>
                            </InputSection>
                        </When>
                        <When condition={trip_amount > 0}>
                            <InputSection>
                                <InputName>Trip</InputName>
                                <InputValue>{formateNumber(trip_amount ?? 0)}</InputValue>
                            </InputSection>
                        </When>
                    </InputContainer>
                </Box>
                <Box>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ backgroundColor: '#E6ECFF26', mt: 3, p: 0.5 }}>
                        <Typography variant='body2' component="div" sx={{ color: '#80ACFF' }}>Deductions</Typography>
                        <Typography variant='body2' component="div" sx={{ color: '#FF3333' }}>{formateNumber(DEDUCTION)}</Typography>
                    </Stack>
                    <InputContainer>
                        {
                            Alldeuctions?.map((item) => {
                                return (
                                    <InputSection>
                                        <InputName>{item?.HEAD_NAME}</InputName>
                                        <InputValue>{formateNumber(item?.head_amount)}</InputValue>
                                    </InputSection>
                                )
                            })
                        }
                        {/* {
                            emp_arrear?.ded_head?.map((item) => {
                                return (
                                    <InputSection>
                                        <InputName>{item?.HEAD_NAME}</InputName>
                                        <InputValue>{formateNumber(item?.head_amount)}</InputValue>
                                    </InputSection>
                                )
                            })

                        } */}
                        {/* <InputSection>
                            <InputName>Income tax</InputName>
                            <InputValue>{formateNumber(computed_obj?.tds || 0)}</InputValue>
                        </InputSection>
                        <When condition={loan_amount < 0}>
                            <InputSection>
                                <InputName>Loans</InputName>
                                <InputValue>{formateNumber((Number(loan_amount) || 0) * -1)}</InputValue>
                            </InputSection>
                        </When>
                        <When condition={adv_amount < 0}>
                            <InputSection>
                                <InputName>Advance</InputName>
                                <InputValue>{formateNumber((Number(adv_amount) || 0) * -1)}</InputValue>
                            </InputSection>
                        </When> */}
                    </InputContainer>
                </Box>
                {/* <Box>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ backgroundColor: '#E6ECFF26', mt: 3, p: 0.5 }}>
                        <Typography variant='body2' component="div" sx={{ color: '#80ACFF' }}>Compliances</Typography>
                        <Typography variant='body2' component="div" sx={{ color: '#00CD37' }}>-</Typography>
                    </Stack>
                    <InputContainer>
                        {
                            compliancesList?.map((item) => {
                                return (
                                    <InputSection>
                                        <InputName>{item?.HEAD_NAME}</InputName>
                                        <InputValue>{formateNumber(item?.head_amount)}</InputValue>
                                    </InputSection>
                                )
                            })
                        }
                    </InputContainer>
                </Box> */}
                <Box>
                    <form onSubmit={remarkSubmitHandler}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ backgroundColor: '#E6ECFF26', mt: 3, p: 0.5, gap: 4, width: '100%' }}>
                            <CustomInput value={remarkText} onChange={({ target }) => setRemarkText(target?.value)} id='payroll_remarks' required label="Remarks" style={{ flex: 1 }} />
                            <SubmitButton variant='contained' type="submit">Add</SubmitButton>
                        </Stack>
                    </form>
                </Box>

            </Box>
        </Box>
    )
}

export { EmployeePayroll };