import { useState, createContext, useContext } from "react"
import { useDispatch } from "react-redux";
import { post, getUser, fetchDataByThread, displayLoader, hideLoader } from "../AppRedux/Actions";
import { FETCH_EMPLOYEES_ATTENDANCE } from "../constants";
const PayrollStore = createContext(null)

export const PayrollProvider = ({ children }) => {
    const dispatch = useDispatch();
    const themecolor = getUser('PRODUCT_THEME_COLOR') || "#335aff";
    const [openModal, setOpenModal] = useState(false);
    const [employeeList, setEmployeeList] = useState({});
    const [finalPayrolls, SetFinalPayrolls] = useState({});
    const [drawerRightOpen, setDrawerRightOpen] = useState(false);
    const [editDetails, setEditDetails] = useState({});
    const [payrollDetail, setPayrollDetail] = useState({});
    const [Drawertype, SetDrawertype] = useState({});
    const [checkedIds, setCheckedIds] = useState({});
    const [runPayrollIds, setRunPayrollIds] = useState({});
    const [activeStep, setActiveStep] = useState(0);
    const [OrgConfig, setOrgConfig] = useState({});
    const [payoutDetails, setPayoutDetails] = useState([]);
    const [payrollSummary, setPayrollSummary] = useState({});
    const [payoutIds, SetPayoutIds] = useState({});
    const [finalNetPayable, SetFinalNetPayable] = useState(0);
    const [disburshmentSummary, setDisburshmentSummary] = useState({});
    const [modalType, SetModaltype] = useState({ componentName: '', size: 'xs' });
    const [employeesArrear, setEmployeesArrear] = useState([]);
    const [allEmployees, setAllEmployees] = useState({});
    const [FinalPayrollsError, SetFinalPayrollsError] = useState([]);
    const [reviewApproval, setReviewApproval] = useState(false);
    const [slugs, setSlugs] = useState({
        "1": {},
        "2": {},
        "3": {}
    });
    const [filterResult, setFilterResult] = useState({
        department: [],
        designation: [],
        location: [],
        hold_status: ["1"],
        pay_status: ['Pending', 'Locked'],
    });


    // Bonus state
    const [heads, setHeads] = useState([]);
    const [editHeads, setEditHeads] = useState({});
    const [editRules, setEditRules] = useState({});
    const [rules, setRules] = useState([]);

    const [employeeError, setEmployeeError] = useState([]);

    //Arrear
    const [arrearIds, setArrearIds] = useState({});

    const FETCH_EMPLOYEES_ATTENDANCE_APICALL = (filter = null, ref = null) => {

        // TODO:reset value when reset filter
        setAllEmployees({});
        setEmployeeError([]);
        setEmployeeList({});
        setCheckedIds({});

        const getFilter = filter ?? filterResult;

        displayLoader(dispatch);
        post(dispatch, '', FETCH_EMPLOYEES_ATTENDANCE, getFilter, {}, (data) => {
            const { emp_attd = {}, errors = [], pending_count = 0, thread_id = null, slug_info = {} } = data;
            setCheckedIds({});
            setSlugs((pre) => ({ ...pre, "1": slug_info }));
            const all = {};
            for (let id in emp_attd) {
                const data = emp_attd[id];
                if (data["sal_status"] !== "Paid") {
                    all[id] = data;
                }
            }
            setAllEmployees({ ...all });
            setEmployeeError([...errors]);
            setEmployeeList({ ...emp_attd });
            let inital_total = (Object.keys(emp_attd)?.length ?? 0) + (errors?.length ?? 0);
            dispatch({ type: 'PERCENTAGE', payload: { percentage: inital_total / (inital_total + pending_count) * 100 } });
            fetchDataByThread(dispatch, pending_count, thread_id, inital_total, ({ response = {}, errors = [] }) => {
                const all = {};
                for (let id in response) {
                    if (response.hasOwnProperty(id)) {
                        const data = response[id];
                        if (data["sal_status"] !== "Paid") {
                            all[id] = data;
                        }
                    }
                }

                setAllEmployees((prevAll) => ({ ...prevAll, ...all }));
                setEmployeeError((prevError) => ([...prevError, ...errors]));
                setEmployeeList((prevEmpList) => ({ ...prevEmpList, ...response }));
                if (ref === "tips") {
                    setCheckedIds({ ...checkedIds, ...all });
                }
                else setCheckedIds({});
            });
            if (ref === "tips") {
                setCheckedIds({ ...all });
            }
        }, () => {
            hideLoader(dispatch);
        }, true);
    }

    const resetContextState = () => {
        setOpenModal(false);
        setEmployeeList({});
        SetFinalPayrolls([]);
        setDrawerRightOpen(false);
        setEditDetails({});
        setPayrollDetail({});
        SetDrawertype({});
        setCheckedIds({});
        setRunPayrollIds({});
        setActiveStep(0);
        setOrgConfig({});
        setPayoutDetails([]);
        setPayrollSummary({});
        SetPayoutIds({});
        SetFinalNetPayable(0);
        setDisburshmentSummary({});
        SetModaltype({ componentName: '', size: 'xs' });
        setEmployeesArrear([]);
        setAllEmployees({});
        setReviewApproval(false);
        SetFinalPayrollsError([]);
        setFilterResult({
            hold_status: ["1"],
            pay_status: ['Pending', 'Locked']
        });
        setHeads([]);
        setEditHeads({});
        setEditRules({});
        setRules([]);
        setEmployeeList([]);
        setEmployeeError([]);
        setArrearIds({});
        setSlugs({
            "1": {},
            "2": {},
            "3": {}

        })
    };

    const contextValues = {
        openModal,
        employeeList,
        setOpenModal,
        setEmployeeList,
        filterResult,
        setFilterResult,
        drawerRightOpen,
        setDrawerRightOpen,
        editDetails,
        setEditDetails,
        checkedIds,
        setCheckedIds,
        activeStep,
        setActiveStep,
        OrgConfig,
        setOrgConfig,
        finalPayrolls,
        SetFinalPayrolls,
        FinalPayrollsError,
        SetFinalPayrollsError,
        payrollDetail,
        setPayrollDetail,
        Drawertype,
        SetDrawertype,
        runPayrollIds,
        setRunPayrollIds,
        payoutDetails,
        setPayoutDetails,
        payrollSummary,
        setPayrollSummary,
        modalType,
        SetModaltype,
        SetPayoutIds,
        payoutIds,
        finalNetPayable,
        SetFinalNetPayable,
        disburshmentSummary,
        setDisburshmentSummary,
        FETCH_EMPLOYEES_ATTENDANCE_APICALL,
        allEmployees,

        //bonus state
        heads, setHeads,
        editHeads, setEditHeads,
        rules, setRules,
        editRules, setEditRules,
        employeeError,
        //arrear
        employeesArrear, setEmployeesArrear,
        arrearIds, setArrearIds,
        //theme
        themecolor,
        resetContextState,

        reviewApproval,
        setReviewApproval,
        slugs, setSlugs
    }



    return (
        <PayrollStore.Provider value={contextValues}>
            {children}
        </PayrollStore.Provider>)
}

export function usePayrollHook() {
    return useContext(PayrollStore)
}