import { getUser } from "../../AppRedux/Actions";
import { INDIAN_COUNTRY_CODE } from "../../utils";

const ACTIVE_COUNTRY = getUser('active_country_id');

export const columlabel = {
    EMPLOYEE_ID: 'Employee',
    emp_name: 'Employee Name',
    department_name: 'Department',
    total_arraer_deduction: 'Arrear Deduction',
    total_arraer_gross: 'Arrear Gross',
    arrear_months: 'Arrear Month',
    total_arrear_days: 'Arrear Days',
    total_net_arrear: 'Arrear Payable'
};


const KEYBASEDONCOUNTRY = ACTIVE_COUNTRY == INDIAN_COUNTRY_CODE ? "compliances" : "employer_deduction";
export const viewArrearKeys = ['earning', 'allowance', 'deduction', KEYBASEDONCOUNTRY];

export const arrearDetails = [
    { name: "Net Amount", id: "NET_PAYABLE" },
    { name: "Gross", id: "computed_gross" },
    { name: "Deduction", id: "DEDUCTION" }
]