import { styled } from "@mui/material/styles";
import React from 'react';
import Tooltip from '@mui/material/Tooltip';

const Circle = styled('div')({
    width: 40,
    height: 40,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '& svg': {
        fontSize: 20
    }
});

const CircleButton = (props) => {
    const { children, ...buttonProps } = props;
    return (
        <Circle className="page-shadow" {...buttonProps}>
            {children}
        </Circle>
    )
}

const TooltipTag = ({ children, title }) => {
    return (
        <Tooltip title={title} arrow placement="top">
            {children}
        </Tooltip>
    )
}


CircleButton.TooltipTag = TooltipTag;


export default CircleButton;