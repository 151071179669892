export const TARGET_KEY = 'FILTER';

export function hashSearch(arr, target, key) {
    const map = new Map();

    for (let i = 0; i < arr.length; i++) {
        map.set(arr[i][key], i);
    }

    return map.has(target) ? true : false;
}

export const filterOptions = (options, condition, valueKey) => {
    return options.reduce((filtered, option) => {
        if (condition(option)) {
            filtered.pass_filter.push(option[valueKey]);
            filtered.store_value.push(option);
        }

        return filtered;
    }, { pass_filter: [], store_value: [] });
};