import { Switch } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
const SwitchIcon = styled(Switch)(({ theme }) => ({
    height:38,
    padding: 9,
    '& .MuiSwitch-switchBase': {
        '&.Mui-checked': {
            color: theme.palette.primary,
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.primary,
            },
            '&.Mui-disabled': {
                color: theme.palette.primary,
                opacity:1
            }
        }
    },
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 12,
            height: 12,
        },
        '&:before': {
            left: 12,
        },
        '&:after': {
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));

export default SwitchIcon;