import React, { useEffect, Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { AutoLogin, SilentLogin } from "../AppRedux/Actions/Auth";
import './global.css';
import FullLoader from "./fullLoader";
import useTabFocus from "../hooks/useTabFocus";
import { getUser } from "../AppRedux/Actions";

const checkVersionChange = (next) => {
    (async (next) => {
        try {
            if (process.env.REACT_APP_BUILD_NUMBER != window?.env?.REACT_APP_BUILD_NUMBER) {
                if ('caches' in window) {
                    const { caches } = window;
                    const cache = await caches.keys();
                    await Promise.all(cache.map((key) => caches.delete(key)));
                }
                if ('serviceWorker' in navigator) {
                    const registrations = await navigator.serviceWorker.getRegistrations();
                    registrations.forEach((registration) => { registration.unregister(); });
                }
                next();
            } else {
                next();
            }
        } catch (err) {
            next();
        }
    })(next);
}

const Login = lazy(() => import("./login"));
const MainApp = lazy(() => import("../components"));
const RestrictedRoute = ({ component: Component, token, ...rest }) => (
    <Route
        {...rest}
        render={
            props =>
                token ?
                    (<Component {...props} socket={rest.socket} />) :
                    (<Redirect to={{ pathname: "/login", state: { from: props.location } }} />)
        }
    />
);
const Components = (props) => {
    const { match, location, history } = props;

    const { user = {}, authUser, initURL, screenSetting, menus } = useSelector(state => state.auth);

    function getFirstUrl(firtMenu) {

        const { children = [], action, menu_version } = firtMenu ?? {};


        if (children.length > 0 && menu_version == 1) {

            for (let target = 0; target < children.length; target++) {
                return getFirstUrl(children[target]);

            }
        }

        return action;


        // const [getfirstChildren] = firtMenu?.children ?? [];

        // if (getfirstChildren) {
        //     return getFirstUrl(getfirstChildren);
        // }
        // else
        //     return `/${firtMenu.url}`;
    }


    useTabFocus(() => {
        const active_country_id = getUser('active_country_id');
        const COUNTRY_BY_REDUX = user?.active_country_id ?? null;
        if (authUser && COUNTRY_BY_REDUX != active_country_id) {
            window.location.href = window.location.origin;
        }
    })

    const dispatch = useDispatch();

    useEffect(() => {
        const url = new URL(window.location.href.replace("/#!", "").replace("/?", "?"));
        let params = {
            token: url.searchParams.get('token') || false,
            redirect: ('/' + (url.searchParams.get('redirect') || ''))
        };
        checkVersionChange(() => {
            if (params.token) {
                dispatch(AutoLogin(params.redirect, params.token, history));
            } else {
                dispatch(SilentLogin());
            }
        });

    }, [dispatch, history]);

    useEffect(() => {
        if (authUser) {
            if (location.pathname === "/" && !(initURL === "" || initURL === "/" || initURL === "/login")) {
                history.replace(initURL);
            } else if (location.pathname === "/login") {
                history.replace(initURL === "/login" ? "/" : initURL);
            }
            if ((location.pathname || "/") === "/" && menus && menus.length > 0) {
                history.replace(getFirstUrl(menus[0]));
            }
        }
    }, [dispatch, authUser, initURL, location.pathname, history, menus]);

    return (screenSetting ? <FullLoader /> :
        <Suspense fallback={<FullLoader />}>
            <Switch>
                <Route exact path="/login" component={Login} />
                <RestrictedRoute path={`${match.url}`} token={authUser} component={MainApp} />
            </Switch>
        </Suspense>
    );

}

export default Components;