import React, { useState } from 'react';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { Input, Typography } from '@mui/material';
import { styled } from "@mui/material/styles";
import "./styles.scss";
import { INDIAN_COUNTRY_CODE, combine, setSymbolOnEmpty, valueWithCurrencySymbol } from '../../utils';
import { COMPUTE_EMPLOYEES_PAYROLL, GET_MISCELLANIOUS_HEADS, MODIFY_EMPLOYEE_PAYROLL } from '../../constants';
import { post, get, getUser } from '../../AppRedux/Actions';
import { useDispatch } from 'react-redux';
import { usePayrollHook } from '../../hooks/usePayrollHook';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import FormBuilder from '../../components/FormBuilder/FormBuilder';
import { AddCircleOutlineRounded, RemoveCircleOutlineOutlined } from '@mui/icons-material';
import { When } from 'react-if';
import { NUMBER_FORMAT } from './constant';

const InputContainer = styled('div')({
    marginTop: 20
});

const InputSection = styled('div')({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 14,
    marginTop: 12,
    marginBottom: 12
});

const InputValue = styled('div')({
    fontSize: 14
});

const InputName = styled('div')({
    fontSize: 14
});

const ButtomButton = styled('div')({
    width: '100%',
    fontSize: 14
});

function EditEmployeePayroll(props) {
    const ACTIVE_COUNTRY = getUser('active_country_id');
    const dispatch = useDispatch();
    const { filterResult: { month_year }, OrgConfig, checkedIds, SetFinalPayrolls, filterResult, SetFinalPayrollsError, FinalPayrollsError, finalPayrolls } = usePayrollHook()
    const { payrollDetail, handleCloseRightDrawer } = props;
    console.log('Enter', payrollDetail);
    const { emp_name,
        EMPLOYEE_ID,
        emp_code,
        NET_PAYABLE,
        computed_gross,
        earning,
        allowance,
        computed_obj,
        miscellaneous_earning,
        miscellaneous_deduction,
        miscellaneous_heads,
        deduction
    } = payrollDetail;
    const [editableValue, setEditableValue] = useState({ tds: computed_obj?.tds || 0 });
    const [miscellaniousEarnings, setMiscellaniousEarnings] = useState([]);
    const [miscellaniousDeductions, setMiscellaniousDeductions] = useState([]);
    const [selectedEarnings, setSelectedEarnings] = useState({});
    const [earningValues, setEarningValues] = useState([]);
    const [selectedDeductions, setSelectedDeductions] = useState({});
    const [deductionValues, setDeductionValues] = useState([]);
    const [earningTypeObject, setEarningTypeObject] = useState({});
    const [deductionTypeObject, setDeductionTypeObject] = useState({});
    const [earningStaticValues, setEarningStaticValues] = useState([]);
    const [deductionStaticValues, setDeductionStaticValues] = useState([]);

    function formateNumber(value) {
        value = setSymbolOnEmpty(value, 0);
        value = Number(value).toFixed(2);
        return valueWithCurrencySymbol(value)
    }

    const handleOnChangeValue = (e) => {
        const { name, value } = e.target;
        setEditableValue((prev) => {
            return {
                ...prev, [name]: value
            }
        })
    }

    function FetchEmployeesPayroll() {
        post(dispatch, '', COMPUTE_EMPLOYEES_PAYROLL, { org_config: OrgConfig, emp_id: Object.keys(checkedIds), recompute: true }, {}, (data) => {
            if (data?.payrolls && data?.payrolls?.length > 0) {
                SetFinalPayrolls([...data.payrolls]);
            }
        });
    }

    function updateDetails() {
        let updateData = {
            emp_code, month_year,
            employee_id: EMPLOYEE_ID,
            miscellaniousEarnings: earningValues,
            miscellaniousDeductions: deductionValues,
            tds: {
                emp_code, month_year, total_tax: editableValue.tds,
                old_tax: computed_obj.tds
            }
        }
        post(dispatch, '', MODIFY_EMPLOYEE_PAYROLL, { emp_details: [checkedIds[EMPLOYEE_ID]], e_data: updateData, filters: filterResult }, {}, (data) => {
            const { payrolls = {}, errors = [] } = data;
            SetFinalPayrolls({ ...finalPayrolls, ...payrolls });
            SetFinalPayrollsError([...FinalPayrollsError, ...errors])
            //FetchEmployeesPayroll();
            handleCloseRightDrawer();
        });
    }
    const editMiscellaniousHead = () => {

        if (miscellaniousEarnings.length <= 0) {
            get(dispatch, '', `${GET_MISCELLANIOUS_HEADS}`, {}, (data) => {
                let { earnings, deductions } = data;
                let { earning: earningHeads } = miscellaneous_heads;
                if (earnings && earnings.length > 0) {
                    let earningsObject = combine(earnings, '{n}.name', '{n}');
                    setEarningTypeObject(combine(earnings, '{n}.id', '{n}'))
                    let selectedEarnings = {}
                    let earningValues = []
                    let earningStaticValues = []
                    if (earningHeads.length > 0) {
                        earningHeads.map((item) => {
                            if (earningsObject.hasOwnProperty(item.name)) {
                                selectedEarnings[earningsObject[item.name].id] = item;
                                earningValues.push({
                                    id: earningsObject[item.name].id,
                                    count: item.amount || 0,
                                    saved: 1,
                                    oldCount: item.amount,
                                    head_name: earningsObject[item.name].head_name
                                })
                            } else {
                                earningStaticValues.push({
                                    name: item.name,
                                    count: item.amount || 0,
                                    saved: 1
                                })
                            }
                        });
                    }
                    earningValues.push({
                        id: '',
                        count: 0
                    })
                    setSelectedEarnings(selectedEarnings)
                    setEarningValues(earningValues)
                    setEarningStaticValues(earningStaticValues)

                }
                setMiscellaniousEarnings(earnings || [])
                setMiscellaniousDeductions(deductions || [])
            });
        } else {
            let earnings = miscellaniousEarnings;
            let { earning: earningHeads } = miscellaneous_heads;

            if (earnings && earnings.length > 0) {

                let earningsObject = combine(earnings, '{n}.name', '{n}');
                setEarningTypeObject(earningsObject)
                let selectedEarnings = {}
                let earningValues = []
                let earningStaticValues = []
                if (earningHeads.length > 0) {
                    earningHeads.map((item) => {
                        if (earningsObject.hasOwnProperty(item.name)) {
                            selectedEarnings[earningsObject[item.name].id] = item;
                            earningValues.push({
                                id: earningsObject[item.name].id,
                                count: item.amount || 0,
                                saved: 1,
                                oldCount: item.amount,
                                head_name: earningsObject[item.name].head_name


                            })
                        } else {
                            earningStaticValues.push({
                                name: item.name,
                                count: item.amount || 0,
                                saved: 1
                            })
                        }
                    });
                }
                earningValues.push({
                    id: '',
                    count: 0
                })
                setSelectedEarnings(selectedEarnings)
                setEarningValues(earningValues)
                setEarningStaticValues(earningStaticValues)

            }
        }
    }
    const editMiscellaniousDeductionHead = () => {
        if (miscellaniousDeductions.length <= 0) {
            get(dispatch, '', `${GET_MISCELLANIOUS_HEADS}`, {}, (data) => {
                let { earnings, deductions } = data;
                let { deduction: deductionHeads } = miscellaneous_heads;
                if (deductions && deductions.length > 0) {

                    let deductonObject = combine(deductions, '{n}.name', '{n}');
                    setDeductionTypeObject(combine(deductions, '{n}.id', '{n}'))

                    let selectedDeduction = {}
                    let deductionValues = []
                    let deductionStaticValues = []
                    if (deductionHeads.length > 0) {
                        deductionHeads.map((item) => {
                            if (deductonObject.hasOwnProperty(item.name)) {
                                selectedDeduction[deductonObject[item.name].id] = item;
                                deductionValues.push({
                                    id: deductonObject[item.name].id,
                                    count: item.amount || 0,
                                    saved: 1,
                                    oldCount: item.amount,
                                    head_name: deductonObject[item.name].head_name

                                })
                            } else {
                                deductionStaticValues.push({
                                    name: item.name,
                                    count: item.amount || 0,
                                    saved: 1
                                })
                            }
                        });
                    }
                    deductionValues.push({
                        id: '',
                        count: 0
                    })
                    setSelectedDeductions(selectedDeduction)
                    setDeductionValues(deductionValues)
                    setDeductionStaticValues(deductionStaticValues)

                }
                setMiscellaniousEarnings(earnings || [])
                setMiscellaniousDeductions(deductions || [])
            });
        } else {
            let deductions = miscellaniousDeductions;
            let { deduction: deductionHeads } = miscellaneous_heads;
            if (deductions && deductions.length > 0) {

                let deductonObject = combine(deductions, '{n}.name', '{n}');
                setDeductionTypeObject(combine(deductions, '{n}.id', '{n}'))

                let selectedDeduction = {}
                let deductionValues = []
                let deductionStaticValues = []
                if (deductionHeads.length > 0) {
                    deductionHeads.map((item) => {
                        if (deductonObject.hasOwnProperty(item.name)) {
                            selectedDeduction[deductonObject[item.name].id] = item;
                            deductionValues.push({
                                id: deductonObject[item.name].id,
                                count: item.amount || 0,
                                saved: 1,
                                oldCount: item.amount
                            })
                        } else {
                            deductionStaticValues.push({
                                name: item.name,
                                count: item.amount || 0,
                                saved: 1
                            })
                        }
                    });
                }
                deductionValues.push({
                    id: '',
                    count: 0
                })
                setSelectedDeductions(selectedDeduction)
                setDeductionValues(deductionValues)
                setDeductionStaticValues(deductionStaticValues)

            }
        }
    }
    const addMiscellaniousEarning = () => {
        setEarningValues([...earningValues, { id: '', count: '' }])
    }
    const addMiscellaniousDeduction = () => {
        setDeductionValues([...deductionValues, { id: '', count: '' }])
    }
    const removeMiscellaniousEarning = (e, index, id) => {
        let newEarningvalues = earningValues;
        newEarningvalues.splice(index, 1);
        setEarningValues([...newEarningvalues])
        let newSelectedEarnings = selectedEarnings;
        delete newSelectedEarnings[id];
        setSelectedEarnings(newSelectedEarnings)

    }
    const handleMiscellaniousEarning = (e, index, item) => {
        let { id } = item
        let newEarningValues = earningValues;
        let changes = false;
        if (e.target.name == 'id') {

            newEarningValues[index] = { id: e.target.value, count: 0, saved: newEarningValues[index].saved || 0, head_name: earningTypeObject[e.target.value].head_name }
            changes = true;
        } else {
            newEarningValues[index] = { ...newEarningValues[index], count: e.target.value, head_name: earningTypeObject[newEarningValues[index].id].head_name }
            changes = true;
        }
        if (changes) {
            setEarningValues([...newEarningValues])
        }
        if (e.target.name == 'id') {
            let newSelectedEarnings = selectedEarnings;
            delete newSelectedEarnings[id];
            newSelectedEarnings = { ...newSelectedEarnings, [e.target.value]: earningValues[index] }
            setSelectedEarnings({ ...newSelectedEarnings })

        }
    }
    const removeMiscellaniousDeduction = (e, index, id) => {
        let newDeductionvalues = deductionValues;
        newDeductionvalues.splice(index, 1);
        setDeductionValues([...newDeductionvalues])
        let newSelectedDeductions = selectedDeductions;
        delete newSelectedDeductions[id];
        setSelectedDeductions(newSelectedDeductions)
    }
    const handleMiscellaniousDeduction = (e, index, item) => {
        let { id } = item
        let newDeductionValues = deductionValues;
        let changes = false;
        if (e.target.name == 'id') {

            newDeductionValues[index] = { id: e.target.value, count: 0, saved: newDeductionValues[index].saved || 0, head_name: deductionTypeObject[e.target.value].head_name }
            changes = true;
        } else {
            newDeductionValues[index] = { ...newDeductionValues[index], count: e.target.value, head_name: deductionTypeObject[newDeductionValues[index].id].head_name }
            changes = true;
        }
        if (changes) {
            setDeductionValues([...newDeductionValues])
        }
        if (e.target.name == 'id') {
            let newSelectedDeductions = selectedDeductions;
            delete newSelectedDeductions[id];
            newSelectedDeductions = { ...newSelectedDeductions, [e.target.value]: deductionValues[index] }
            setSelectedDeductions({ ...newSelectedDeductions })

        }
    }

    const grosspayDetails = [...earning, ...allowance];
    return (
        <Box sx={{ width: 400, height: "100%" }} role="presentation" className="edit-employee">
            <Box className="fixed-head">
                <Box sx={{ float: 'right', m: 1, cursor: 'pointer' }}><CloseIcon onClick={handleCloseRightDrawer} /></Box>
            </Box>
            <Box sx={{ p: 2, pb: 8 }}>
                <Box>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant='body2' component="div" sx={{ color: '#4D70FF' }}>{setSymbolOnEmpty(emp_name, "-")}</Typography>
                        <Typography component="div">Net Payable</Typography>
                    </Stack>
                </Box>
                <Box>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography component="div">Emp  Code :{setSymbolOnEmpty(emp_code, "-")}</Typography>
                        <Typography component="div">{formateNumber(NET_PAYABLE)}</Typography>
                    </Stack>
                </Box>
                <Box>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ backgroundColor: '#E6ECFF26', mt: 3, p: 0.5 }}>
                        <Typography variant='body2' component="div" sx={{ color: '#00CD37' }}>Earnings</Typography>
                        <Typography variant='body2' component="div" sx={{ color: '#00CD37' }}>{formateNumber(computed_gross)}</Typography>
                    </Stack>
                    <InputContainer>
                        {
                            grosspayDetails?.map((item) => {
                                return (
                                    <InputSection>
                                        <InputName>{item?.DISPLAY_NAME || item?.HEAD_NAME}</InputName>
                                        <InputValue>{formateNumber(item?.head_amount)}</InputValue>
                                    </InputSection>
                                )
                            })
                        }
                    </InputContainer>
                    <InputContainer>
                        <InputSection >
                            <InputName sx={{ display: 'flex', alignItems: "center" }}>
                                Miscellaneous Earning

                                <Box className="icon-rounded" onClick={() => { editMiscellaniousHead() }} sx={{ padding: '5px', marginLeft: '5px' }}>
                                    <ModeEditOutlineOutlinedIcon sx={{ fontSize: 20, color: '#4D8BFF' }} />
                                </Box>
                            </InputName>
                            <InputValue>{formateNumber(miscellaneous_earning)}</InputValue>
                        </InputSection>
                        <InputSection className="miscellaniousEarningEdit" style={{ flexDirection: "column", alignItems: "end" }}>
                            {
                                earningStaticValues.map((item) => {
                                    return (
                                        <Stack direction="row" justifyContent="space-between" rowGap={2} sx={{
                                            width: "100%", marginTop: '10px', paddingRight: '39px',
                                            paddingBottom: '5px'
                                        }}>
                                            <InputName>
                                                {item.name}
                                            </InputName>
                                            <InputValue>{formateNumber(item.count)}</InputValue>
                                        </Stack>
                                    );
                                })
                            }
                            {
                                earningValues.map((item, indexCount) => {
                                    return (
                                        <Stack direction="row" justifyContent="space-between" rowGap={2} sx={{ width: "100%", marginTop: '10px' }}>
                                            {
                                                <FormBuilder size={[6, 6, 6, 6]} fields={
                                                    [
                                                        {
                                                            type: 'select', name: 'id', label: 'Miscellaneous Head', options: miscellaniousEarnings.filter((earning) => {
                                                                return !selectedEarnings.hasOwnProperty(earning.id) || earning.id == item.id;
                                                            }), disabled: item.saved
                                                        },
                                                        { type: 'input', name: 'count', validate: NUMBER_FORMAT, label: '', disabled: !item.id }
                                                    ]
                                                } values={earningValues[indexCount]} inputHandler={(e) => { handleMiscellaniousEarning(e, indexCount, item) }} />
                                            }
                                            <Box className={"icon-rounded-transparent"} sx={{ padding: '5px', marginTop: '10px', marginLeft: '10px' }}>
                                                {!item.saved ? <RemoveCircleOutlineOutlined onClick={(e) => { removeMiscellaniousEarning(e, indexCount, item.id) }} sx={{ fontSize: 20, color: '#FF3333' }} /> : null}
                                            </Box>
                                        </Stack>
                                    );
                                })
                            }
                            {earningValues.length > 0 && earningValues.length < miscellaniousEarnings.length ? <Box className="icon-rounded" onClick={addMiscellaniousEarning} sx={{ padding: '5px', marginTop: '10px' }}>
                                <AddCircleOutlineRounded sx={{ fontSize: 20, color: '#4D8BFF' }} />
                            </Box> : null}

                        </InputSection>
                    </InputContainer>
                </Box>
                <Box>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ backgroundColor: '#E6ECFF26', mt: 3, p: 0.5 }}>
                        <Typography variant='body2' component="div" sx={{ color: '#FF3333' }}>Deductions</Typography>
                        <Typography variant='body2' component="div" sx={{ color: '#FF3333' }}>Amount</Typography>
                    </Stack>
                    <When condition={ACTIVE_COUNTRY == INDIAN_COUNTRY_CODE}>
                        <InputContainer>
                            <InputSection>
                                <InputName>Income Tax</InputName>
                                <InputValue><Input variant="outlined" name='tds' value={editableValue?.tds} onChange={handleOnChangeValue} className="input" /></InputValue>
                            </InputSection>
                        </InputContainer>
                    </When>
                    <InputContainer>
                        {
                            deduction?.map((item) => {
                                return (
                                    <InputSection>
                                        <InputName>{item?.DISPLAY_NAME || item?.HEAD_NAME}</InputName>
                                        <InputValue>{formateNumber(item?.head_amount)}</InputValue>
                                    </InputSection>
                                )
                            })
                        }
                    </InputContainer>
                    <InputContainer>
                        <InputSection >
                            <InputName sx={{ display: 'flex', alignItems: "center" }}>
                                Miscellaneous Deduction

                                <Box className="icon-rounded" onClick={() => { editMiscellaniousDeductionHead() }} sx={{ padding: '5px', marginLeft: '5px' }}>
                                    <ModeEditOutlineOutlinedIcon sx={{ fontSize: 20, color: '#4D8BFF' }} />
                                </Box>
                            </InputName>
                            <InputValue>{formateNumber(miscellaneous_deduction)}</InputValue>
                        </InputSection>
                        <InputSection className="miscellaniousEarningEdit" style={{ flexDirection: "column", alignItems: "end" }}>
                            {
                                deductionStaticValues.map((item) => {
                                    return (
                                        <Stack direction="row" justifyContent="space-between" rowGap={2} sx={{
                                            width: "100%", marginTop: '10px', paddingRight: '39px',
                                            paddingBottom: '5px'
                                        }}>
                                            <InputName>
                                                {item.name}
                                            </InputName>
                                            <InputValue>{formateNumber(item.count)}</InputValue>
                                        </Stack>
                                    );
                                })
                            }
                            {
                                deductionValues.map((item, indexCount) => {
                                    return (
                                        <Stack direction="row" justifyContent="space-between" rowGap={2} sx={{ width: "100%", marginTop: '10px' }}>
                                            {
                                                <FormBuilder size={[6, 6, 6, 6]} fields={
                                                    [{
                                                        type: 'select', name: 'id', label: 'Miscellaneous Head', options: miscellaniousDeductions.filter((deduction) => {
                                                            return !selectedDeductions.hasOwnProperty(deduction.id) || deduction.id == item.id;
                                                        }), disabled: item.saved
                                                    },
                                                    { type: 'input', name: 'count', validate: NUMBER_FORMAT, label: '', disabled: !item.id }
                                                    ]
                                                } values={deductionValues[indexCount]} inputHandler={(e) => { handleMiscellaniousDeduction(e, indexCount, item) }} />
                                            }
                                            <Box className={"icon-rounded-transparent"} sx={{ padding: '5px', marginTop: '10px', marginLeft: '10px' }}>
                                                {!item.saved ? <RemoveCircleOutlineOutlined onClick={(e) => { removeMiscellaniousDeduction(e, indexCount, item.id) }} sx={{ fontSize: 20, color: '#FF3333' }} /> : null}
                                            </Box>
                                        </Stack>
                                    );
                                })
                            }
                            {deductionValues.length > 0 && deductionValues.length < miscellaniousDeductions.length ? <Box className="icon-rounded" onClick={addMiscellaniousDeduction} sx={{ padding: '5px', marginTop: '10px' }}>
                                <AddCircleOutlineRounded sx={{ fontSize: 20, color: '#4D8BFF' }} />
                            </Box> : null}

                        </InputSection>
                    </InputContainer>
                </Box>
            </Box>
            <Box sx={{
                position: "fixed",
                bottom: 0,
                width: "inherit",
                background: "white",
                zIndex: 1
            }}>
                <ButtomButton>
                    <Divider />
                    <Stack direction="row" justifyContent="flex-end" gap={2} sx={{ mt: 1, p: 1 }}>
                        <Button variant='outlined' onClick={handleCloseRightDrawer} sx={{ borderColor: '#172B4D' }}>Cancel</Button>
                        <Button variant='filled' sx={{ color: 'white', background: '#172B4D' }} onClick={updateDetails}>Save</Button>
                    </Stack>
                </ButtomButton>
            </Box>
        </Box>
    )
}

export default EditEmployeePayroll;