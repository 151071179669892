import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { filterOptions, TARGET_KEY } from './constant';
import { Typography } from '@mui/material';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CustomAutoComplete = React.memo(({
    options,
    required = false,
    name,
    inputHandler,
    placeholder = 'select...',
    values,
    valueKey = 'id',
    labelKey = 'name',
    isMulti = false,
    additionalInfo = {},
    ...props
}) => {
    const [selectedDefaultOptions, setSelectedOptions] = React.useState([]);

    const allTarget = [{ [valueKey]: TARGET_KEY, [labelKey]: `All ${additionalInfo.label}` }];
    const KEY_FOR_REMOVE_ITEMS = 'removeOption';
    const KEY_FOR_SELECT_ITEMS = 'selectOption';

    const formattedOptions = React.useMemo(() => {
        return options?.map(item => {
            if (typeof item === 'string' || typeof item === 'number') {
                return { [valueKey]: item, [labelKey]: item };
            }
            return item;
        });
    }, [options]);



    const formattedValue = React.useMemo(() => {
        if (!isMulti && (values || values == 0)) {
            return formattedOptions?.find(option => `${option[valueKey]}` === `${values}`);
        }
        else if (isMulti && values && values?.length > 0) {
            if (Array.isArray(values)) {
                const list_valiues = values?.map(val => formattedOptions.find(option => `${option[valueKey]}` === `${val}`));
                setSelectedOptions([...list_valiues]);
                return list_valiues;
            }
        }

        return [];

    }, [isMulti, values]);


    const isSelectAllSelected = React.useMemo(() => {
        return selectedDefaultOptions.length === formattedOptions?.length;
    }, [selectedDefaultOptions, formattedOptions]);

    const optionsWithSelectAll = isSelectAllSelected ? [...allTarget, ...(formattedValue ?? [])] : formattedValue;

    // Handle change event
    const handleChange = React.useCallback((_, value, reason, details) => {
        const { option = {} } = details ?? {};

        let selectedValues = {};
        if (isMulti) {
            if (option[valueKey] == TARGET_KEY && reason === KEY_FOR_SELECT_ITEMS) {
                selectedValues = filterOptions(
                    formattedOptions,
                    option => option[valueKey] !== TARGET_KEY,
                    valueKey
                );
            } else if (option[valueKey] == TARGET_KEY && reason === KEY_FOR_REMOVE_ITEMS) {
                selectedValues = { pass_filter: [], store_value: [] };
            } else {
                selectedValues = filterOptions(
                    value,
                    option => option[valueKey] !== TARGET_KEY,
                    valueKey
                );
            }
            setSelectedOptions([...selectedValues.store_value]);
            inputHandler({ target: { name, value: selectedValues.pass_filter } });
        } else {
            inputHandler({ target: { name, value: value ? value[valueKey] : '' } });
        }
    }, [isMulti, formattedOptions, valueKey, inputHandler, name]);

    return (
        <Autocomplete
            disabled={additionalInfo?.disabled}
            readOnly={additionalInfo?.readOnly}
            noOptionsText={`No ${additionalInfo.label} Found`}
            autoComplete={true}
            openOnFocus={true}
            slotProps={{
                paper: {
                    style: { marginTop: 8 }
                }
            }}
            size='small'
            multiple={isMulti}
            onChange={handleChange}
            options={isMulti ? [...allTarget, ...formattedOptions] : formattedOptions}
            disableCloseOnSelect={isMulti}
            value={optionsWithSelectAll}
            ListboxProps={{ style: { maxHeight: 250 } }}
            getOptionLabel={(option) => option[labelKey] ?? ""}
            sx={{
                '.MuiAutocomplete-inputRoot': {
                    flexWrap: "nowrap !important"
                }
            }}
            renderTags={(value, getTagProps) => {
                const numTags = value.length;

                return (
                    <Typography sx={{
                        fontSize: 14,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap"
                    }}>
                        {value
                            .slice(0, 2)
                            .filter((option) => option && option?.valueKey !== TARGET_KEY)
                            .map((option, _) => option[labelKey])
                            .join(", ")}

                        {numTags > 2 && ` +${numTags - 2} more`}
                    </Typography>
                );
            }}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    {isMulti &&
                        <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 1 }}
                            checked={selected}
                        />}
                    {option[labelKey]}
                </li>
            )}
            renderInput={(params) => (
                <TextField {...params} required={required} label={additionalInfo?.label} placeholder={optionsWithSelectAll?.length ? null : placeholder} />
            )}
        />
    );
});

export default CustomAutoComplete;
