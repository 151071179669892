import React from 'react';
import { getLowerCaseValue } from '../../utils';
import './styles.scss';
import { ColorValue } from '../../Config/CommonStyles';

function capitalizeFirstLetter(string = '') {
    return string?.split(' ').map((item)=> (item?.charAt(0)?.toUpperCase() + item?.slice(1)))?.join(' ');
}

const Status = ({ title, bgColor, color, ...rest }) => {
    return (
        <ColorValue {...{ bgColor, color }}>
            {title}
        </ColorValue>
        // <Stack className='status-badge' direction="row" alignItems="center" sx={{ backgroundColor: bgColor, color: color, border: `1px solid ${color}` }} {...rest}>
        //     <Box sx={{ pl: 2, pr: 2, fontSize: 14, fontWeight: 400, boxSizing: 'border-box' }}>
        //         {title}
        //     </Box>
        // </Stack>
    )
}

// const Default = ({ title }) => {
//     return (
//         <Stack className='default status-badge' direction="row" alignItems="center" justifyContent="center">
//             <Box>
//                 <FiberManualRecordIcon sx={{ width: '0.75rem', height: '0.75rem' }} />
//             </Box>
//             <Box sx={{ pl: 1, pr: 1, boxSizing: 'border-box' }}>
//                 {title}
//             </Box>
//         </Stack>
//     )
// }

const renderCurrentSelection = (result, status = '') => {
    switch (result) {
        case 'paid': return <Status title={capitalizeFirstLetter(result)} bgColor="#18c2441c" color="#056116" />;
        case 'yes': return <Status title={capitalizeFirstLetter(result)} bgColor="#18c2441c" color="#056116" />;
        case 'no': 
        case 'pending':
        case 'partially': return <Status title={capitalizeFirstLetter(result)} bgColor="#FAC8001A" color="#a6880a" />;
        case 'locked':
        case 'unmapped': return <Status title={capitalizeFirstLetter(result)} bgColor="#0e7ad42b" color="#176c96" />;
        case 'processed':
        case 'mapped': return <Status title={capitalizeFirstLetter(result)} bgColor="#00CD371A" color="#00CD37" />;
        case 'generated': return <Status title={capitalizeFirstLetter(result)} bgColor="#4C8BFF1A" color="#4C8BFF" />;
        case 'published': return <Status title={capitalizeFirstLetter(result)} bgColor="#4C8BFF1A" color="#4C8BFF" />;
        case 'approval received': return <Status title={capitalizeFirstLetter(result)} bgColor="#18c2441c" color="#056116" />   // for change the color for status Approval Received
        case 'processed(approval received)': return <Status title={'Processed(Approval Received)'} bgColor="#18c2441c" color="#056116" />   // for change the color for status Approval Received
        default: return <Status title={capitalizeFirstLetter(result)} bgColor="#f83d3d1a" color="#e74b1e" />

    }
};

function StatusColumn({ status }) {
    return (
        <React.Fragment>
            {renderCurrentSelection(getLowerCaseValue(status), status)}
        </React.Fragment>
    )
}

export default StatusColumn;